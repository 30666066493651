// export const base = "http://localhost:5001/lifevize-bbfd0/us-central1";
export const base = "https://us-central1-lifevize-bbfd0.cloudfunctions.net";
// export const base = 'http://localhost:5006';

export const config = async () => {
  let token = await localStorage.getItem("userToken");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };
};

export const multipartConfig = async () => {
  let token = await localStorage.getItem("userToken");
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  };
};
